<template>
<div class="body">
    <!-- 选择模板类型 -->
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      height="550px">
      <el-table-column prop="id" label="ID" width="50" align="center"> </el-table-column>
      
      <el-table-column prop="userId" label="用户ID" width="300" align="left"> </el-table-column>
      <el-table-column prop="appId" label="小程序" width="100" align="left">
          <template slot-scope="scope">
              <span>{{getAppName(scope.row.appId)}}</span>
          </template>
      </el-table-column>
      <!-- <el-table-column prop="openId" label="用户openID" width="180"> </el-table-column> -->
      <!-- <el-table-column prop="templateId" label="模板ID" width="400" align="center"> </el-table-column> -->
      <el-table-column prop="page" label="消息跳转路径" width="180" align="center"> </el-table-column>
      <el-table-column prop="data" label="模板详情" width="110" align="center">
          <template slot-scope="scope">
              <span @click="queryData(scope.row.data)">点击查看详情</span>
          </template>
      </el-table-column>
      <el-table-column prop="miniprogramState" label="小程序类型" width="100" align="center">
          <template slot-scope="scope">
              <span>{{getMiniprogramState(scope.row.miniprogramState)}}</span>
          </template>
      </el-table-column>
      <el-table-column prop="sendStatus" label="发送状态" width="80" align="left">
          <template slot-scope="scope">
              <span>{{getSendStatus(scope.row.sendStatus)}}</span>
          </template>
      </el-table-column>
      <el-table-column prop="sendTime" label="发送时间" width="200"> </el-table-column>
      <el-table-column prop="sendMsg" label="发送信息" width="180">
          <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.sendMsg" placement="top-start">
                <div class="showoverTooltip">{{scope.row.sendMsg}}</div>
            </el-tooltip>
          </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="100" align="center">
          <template slot-scope="scope">
              <span>{{scope.row.createTime ? scope.row.createTime : '-'}}</span>
          </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="更新时间" width="100" align="center">
          <template slot-scope="scope">
              <span>{{scope.row.updateTime ? scope.row.updateTime : '-'}}</span>
          </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    <!-- 查看模板详情dialog -->
    <el-dialog
        title="模板详情"
        :visible.sync="dialogVisible"
        width="30%">
        <div v-for="(item,index) in moduleList" :key="index">
            <!-- <span>{{item.name}}</span> -->
            <div>{{item.value}}</div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
    </el-dialog>
</div>
</template>
<script>
import {getSubscribeList,addNewSubscribe} from "@/api/config_center/miniprogram_subscribe";//专区活动列表API
export default {
    name:'subscribeNews',
    data() {
        return{
            tableData:[],
            pageNo:1,
            pageSize:10,
            total:0,
            dialogVisible:false,
            moduleList:[]
        }
    },
    mounted(){
        this.getList()
    },
    methods:{
        getList(){
            let params = {
                pageNo:this.pageNo,
                pageSize:this.pageSize,
            }
            getSubscribeList(params).then(res=>{
                console.log(res)
                this.tableData = res.data.body.list
                this.total = res.data.body.total
            })
        },
        handleSizeChange(e){
            console.log(e)
            this.pageSize = e
            this.getList()
        },
        handleCurrentChange(e){
            console.log(e)
            this.pageNo = e
            this.getList()
        },
        // 查看模板详情交互函数
        queryData(e){
            this.moduleList = JSON.parse(e)
            console.log(e,this.moduleList)
            this.dialogVisible = true
        },

        // 以下都是字段转中文操作---------------------------start-----------------------
        // 小程序类型标签转中文解释
        getMiniprogramState(e){
            if(e == 'developer'){
                return '开发版'
            }else if(e == 'developer'){
                return '体验版'
            }else if(e == 'formal'){
                return '正式版'
            }
        },
        // 小程序ID转中文名
        getAppName(e){
            if(e == 'wx596fe1174d202155'){
                return '丽日购'
            }else if(e == 'wx3dcd219e763e2bbe'){
                return '丽日购测试'
            }else{
                return '其他小程序'
            }
        },
        // 发送状态转中文
        getSendStatus(e){
            if(e == 1){
                return '待发送'
            }else if(e == 2){
                return '已发送'
            }else if(e == 3){
                return '成功'
            }else if(e == 4){
                return '失败'
            }
        },
        // 以上都是字段转中文操作---------------------------start-----------------------
    },

}
</script>
<style lang='scss' scoped>
.showoverTooltip{
    overflow: hidden;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
</style>