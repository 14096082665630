import request from '@/utils/request'

// 获取订阅推送记录列表
export function getSubscribeList (data) {
    return request({
        url: '/small-inteface/wxma/message/sendMessageRecordPage',
        method: 'post',
        data
    })
}
// 新增订阅消息推送
export function addNewSubscribe (data) {
    return request({
        url: '/small-inteface/wxma/message/bathSaveSendMessageRecord',
        method: 'post',
        data
    })
}